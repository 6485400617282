import request from '@/utils/request'
import Qs from 'qs'
// 获取产品案例列表
export function getProductCaseList(query) {
	return request({
		url: '/order/product/getProductCaseList',
		method: 'get',
		params: query
	})
}
export function getProductPriceInfo(query) {
	return request({
		url: '/order/product/getProductPriceInfo',
		method: 'get',
		params: query
	})
}
//获取产品介绍
export function getProductIntroduce(query) {
	return request({
		url: '/order/product/getProductIntroduce',
		method: 'get',
		params: query
	})
}
//获取产品案例详情
export function getProductCaseInfo(query) {
	return request({
		url: '/order/product/getProductCaseInfo',
		method: 'get',
		params: query
	})
}
//根据邀请码获取邀请人昵称
export function codeNickName(query) {
	return request({
		url: '/share/invite/name',
		method: 'get',
		params: query
	})
}
// 所有国家
export function cityNations(query) {
	return request({
		url: '/city/nations',
		method: 'get',
		params: query
	})
}
//获取国家以及区号
export function getPhoneCode(query) {
	return request({
		url: '/index/getPhoneCode',
		method: 'get',
		params: query
	})
}
//短信验证码发送
export function getPhoneNumber(data) {
	return request({
		url: '/captcha/phoneNumber',
		method: 'post',
		data: data
	})
}

//上传BASE64
export function uploadBase64(data) {
	return request({
		url: `/upload/uploadBase64`,
		method: 'post',
		data: data
	})
}

// //房源海报
// export function housePoster(houseId) {
// 	return request({
// 		url: `/share/house/poster?houseId=${houseId}`,
// 		method: 'post',
// 	})
// }

//带参菊花码
export function posterCode(qrType,params) {
	return request({
		url: `/share/qrCode?qrType=${qrType}&params=${params}`,
		method: 'post',
	})
}

//设置活动海报
export function setActivityPoster(data) {
	return request({
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: `/index/setActivityPoster`,
		data: Qs.stringify(data),
		method: 'post'
	})
}
